import styled from "styled-components";

export const UploadPhotoContainer = styled.div`
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;

  .uploadImage {
    height: 188px;
    width: 300px;
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "white")};
    border: 1px solid rgba(0, 122, 177, 0.30);
    border-radius: 6px;
    display: flex;

    .uploadButton {
      background: #69c2ff;
      border-radius: 3px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }

  .innerDivImageContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: normal;
  }

  .innerDivImageContainer img {
    height: 188px;
    width: 300px;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid rgba(0, 122, 177, 0.30);
    
    > img {
      width: 100%;
      height: 100%;
    }
  }

  .innerDivImageContainer .infoBtn {
    width: 24px;
    height: 24px;
    font-size: 15px;
    color: #fff;
    border-radius: 50%;
    background-color: rgba(0, 92, 135, 0.3);
  }

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .innerDivImageContainer {
      width: 125px;
      height: 125px;
      border-radius: 50%;
      overflow: hidden;
    }

    .innerDivImageContainer .infoBtn {
      display: none;
    }

    .innerDivImageContainer img {
      width: 125px;
      height: 125px;
      border-radius: 50%;
    }
  }
`;

export const RemoveImageV2 = styled.div`
  position: relative;

  .main-div {
    position: absolute;
    right: -10px;
    top: -10px;
  }

  .icon-div {
    background: #007ab1;
    padding: 0 8px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .sub-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InputImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${({ bgColor }) => (bgColor ? "#85c0ea" : "#69c2ff")};
  font-size: 18px;
  margin: 0 25px;
  border-radius: 3px;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 0;
  margin: auto;

  > i {
    font-family: rubik-medium;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
  }

  @media (max-width: 600px) {
    position: absolute;
    background-color: #c4c4c4;
    margin: 0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
    font-size: 10px;

    > i {
      font-size: 10px;
    }
  }

  .middle-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .title {
    color: #007ab1;
    text-align: center;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 12px;
    text-transform: capitalize;
  }

  .sub-title {
    color: #007ab1;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: lowercase;
  }

  .flyer-name {
    color: #76ab78;
    text-align: center;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .input-section{
    visibility:hidden;
  }
`;


